import React from 'react'
// import "./styles/global.css"
import {ChakraProvider} from '@chakra-ui/react'
import './common/style/global.css'

export const wrapPageElement = ({element, props}) => (
  <React.Fragment>
    {/* <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <meta name="theme-color" content="#68db85" />
      <link rel="manifest" href="/site.webmanifest" />
    </Helmet> */}

    <ChakraProvider {...props}>{element}</ChakraProvider>
  </React.Fragment>
)
